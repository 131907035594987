<template>
    <ValidationObserver ref="leaveOfAbsenceForm">
        <b-container class="mt-5">
            <b-row>
                <b-col>
                    <b-card class="p-5">
                        <b-row class="text-center">
                            <b-col cols="12" class="d-flex align-items-center justify-content-between mb-3">
                                <img src="../../../assets/img/BAU_Logo.png" height="75" alt="BAU Logo" />
                                <h6 class="mb-0" v-html="$t('bau_inspiration_text')"></h6>
                            </b-col>
                            <b-col>
                                <h4 class="mt-3 font-weight-bold">{{ $t('leave_of_absence_title') }}</h4>
                            </b-col>
                        </b-row>
                        <!-- Student Information -->
                        <b-row>
                            <b-col cols="12">
                                <ValidationProvider name="name" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('name')">
                                        <b-form-input v-model="form.name">
                                        </b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col sm="12" md="4">
                                <ValidationProvider name="phone" rules="required" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('mobile_number')">
                                        <b-form-input
                                            :placeholder="$t('mobile_placeholder')"
                                            v-mask="'+1 (###)-###-####'"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            v-model="form.phone"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col sm="12" md="4">
                                <ValidationProvider name="email" rules="required|email" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('email')">
                                        <b-form-input type="text"
                                                      v-model="form.email"
                                                      :state="errors[0] ? false : (valid ? true : null)">
                                        </b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]" :v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col sm="12" md="4">
                                <ValidationProvider name="student_id" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('student_id')">
                                        <b-form-input v-model="form.student_id">
                                        </b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                        </b-row>
                        <!-- Current Address -->
                        <b-row>
                            <b-col cols="12">
                                <h5 class="font-weight-bold">{{ $t('current_address') }}</h5>
                                <hr />
                            </b-col>
                            <b-col cols="12">
                                <ValidationProvider name="street_1" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('street_1')">
                                        <b-form-input v-model="form.street_1">
                                        </b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col cols="12">
                               <ValidationProvider name="street_2" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('street_2')">
                                        <b-form-input v-model="form.street_2">
                                        </b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col sm="12" md="4">
                                <ValidationProvider name="city" rules="" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('city')">
                                        <city-selectbox
                                            :validateError="errors[0]"
                                            v-model="form.city">
                                        </city-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col sm="12" md="4">
                                <ValidationProvider name="district" rules="" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('state')">
                                        <district-outline-selectbox
                                            :city_id="form.city"
                                            :validateError="errors[0]"
                                            v-model="form.district">
                                        </district-outline-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col sm="12" md="4">
                                <ValidationProvider name="address_zip_code" rules="required" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('zip_code')">
                                        <b-form-input
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            v-model="form.address_zip_code">
                                        </b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                        </b-row>
                        <!-- Semester Information -->
                        <b-row>
                            <b-col md="4">
                                <label class="col-form-label mr-2">{{ $t('absent_semester') }}</label>
                            </b-col>
                            <b-col md="8">
                                <ValidationProvider name="absentSemester" rules="required"  v-slot="{valid, errors}">
                                    <b-form-group>
                                        <multi-selectbox :allow-empty="false"
                                                         :multiple="false"
                                                         v-model="form.absentSemester"
                                                         :options="semesterOptions"
                                        >
                                        </multi-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col md="4">
                                <label class="col-form-label mr-2">{{ $t('return_semester') }}</label>
                            </b-col>
                            <b-col md="8">
                                <b-form-group>
                                    <b-form-input v-model="form.returnSemester" :disabled="true" />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <!-- Reason for Leave of Absence -->
                        <b-row>
                            <b-col cols="12">
                                <ValidationProvider name="reason" rules="required" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('leave_of_absence_select')" stacked>
                                        <b-form-radio v-model="form.reason" value="medical">
                                            {{ $t('leave_of_absence_reason_medical') }}
                                        </b-form-radio>
                                        <b-form-radio v-model="form.reason" value="financial" :disabled="form.isInternational">
                                            {{ $t('leave_of_absence_reason_financial') }}
                                        </b-form-radio>
                                        <b-form-radio v-model="form.reason" value="death">
                                            {{ $t('leave_of_absence_reason_death') }}
                                        </b-form-radio>
                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                        </b-row>
                        <!-- Explanation -->
                        <b-row>
                           <b-col cols="12">
                               <ValidationProvider name="explanation" rules="required" v-slot="{valid, errors}">
                                   <b-form-group :label="$t('leave_of_absence_explanation')">
                                       <b-form-textarea v-model="form.explanation"
                                                        :class="errors[0] ? 'is-invalid':''"
                                                        rows="5"
                                       />
                                       <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                   </b-form-group>
                               </ValidationProvider>
                           </b-col>
                        </b-row>
                        <!-- Attach Documents -->
                        <b-row>
                            <b-col cols="12">
                                <ValidationProvider name="file" rules="" v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('attach_supporting_file')">
                                        <div class="d-flex custom-file-upload">
                                            <b-form-file v-model="form.file" :state="errors[0] ? false : (valid ? true : null)"
                                                         :placeholder="$t('select_file')" :drop-placeholder="$t('drop_file')"
                                                         ref="fileInput"></b-form-file>
                                            <b-button variant="outline-secondary" @click="$refs.fileInput.$el.childNodes[0].click();">{{
                                                    $t('browse') }}</b-button>
                                        </div>
                                        <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                        </b-row>
                        <!-- Signature -->
                        <b-row>
                            <b-col cols="12">
                                <p class="font-weight-bold">
                                    {{ $t('leave_of_absence_acknowledgment') }}
                                </p>
                            </b-col>
                            <b-col sm="12" md="6">
                                <ValidationProvider name="student_signature" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('student_signature')">
                                        <b-form-input type="text" v-model="form.student_signature"
                                                      :state="errors[0] ? false : null" />
                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col sm="12" md="6">
                                <ValidationProvider name="date" rules="" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('date')">
                                        <select-date v-model="form.date" :validation-error="errors[0]"/>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                        </b-row>
                        <b-button @click="sendForm" variant="primary">{{ $t('send') }}</b-button>
                    </b-card>
                </b-col>
            </b-row>
        </b-container>
    </ValidationObserver>
</template>
<script>
import CitySelectbox from "@/components/interactive-fields/CitySelectbox.vue";
import DistrictOutlineSelectbox from "@/components/interactive-fields/DistrictOutlineSelectbox.vue";
import MultiSelectbox from "@/components/interactive-fields/MultiSelectbox.vue";

export default {
    components: {
        MultiSelectbox,
        DistrictOutlineSelectbox,
        CitySelectbox
    },
    data() {
        return {
            form: {
                name: null,
                phone: null,
                email: null,
                student_id: null,
                street_1: null,
                street_2: null,
                city: null,
                district: null,
                address_zip_code: null,
                absentSemester: null,
                returnSemester: null,
                reason: null,
                explanation: null,
                file: null,
                student_signature: null,
                date: null,
            },
            semesterOptions: [{value: 1, text: this.$t('fall_2024')}, {value: 2, text: this.$t('spring_2025')}],
        };
    },
    metaInfo() {
        return {
            title: this.$t("leave_of_absence_title"),
        };
    },
    watch: {
        form: {
            handler(val) {
                if (val.absentSemester === 1) {
                    this.form.returnSemester = this.$t('spring_2025');
                } else if (val.absentSemester === 2) {
                    this.form.returnSemester = this.$t('summer_2025');
                }
            },
            deep: true,
        },
    },
    methods: {
        sendForm() {
        },
    },
};
</script>
